<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-6">
            <a-form-item label="项目">
              <a-select
                class="form-control"
                v-decorator="['projectCode']"
                placeholder="请选择"
                allowClear
                showSearch
                :options="projectList"
                :filterOption="filterByPY"
              ></a-select>
            </a-form-item>
            <a-form-item label="设备名称">
              <a-input
                class="form-control"
                v-decorator="['equipmentName']"
                placeholder="请输入设备名称"
                allowClear
              ></a-input>
            </a-form-item>
            <a-form-item label="设备编号">
              <a-input
                class="form-control"
                v-decorator="['equipmentId']"
                placeholder="请输入设备id"
                allowClear
              ></a-input>
            </a-form-item>
            <a-form-item label="门禁供应商">
              <a-input
                class="form-control"
                v-decorator="['provider']"
                placeholder="请输入门禁供应商"
                allowClear
              ></a-input>
            </a-form-item>
            <a-form-item label="方向">
              <a-select
                class="form-control"
                allowClear
                placeholder="请选择"
                v-decorator="['direction']"
                :options="typesMap.direction"
              ></a-select>
            </a-form-item>
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions">
          <a-button @click="exportExcel">
            <a-icon type="export"></a-icon>
            导出
          </a-button>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="equipmentId"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <DeviceDetail ref="DeviceDetail"></DeviceDetail>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import { getProjectList } from '@/service/getData'
import DeviceDetail from './modules/DeviceDetail'
import fileDownload from 'js-file-download'

export default {
  mixins: [queryViewMixin],
  components: {
    DeviceDetail,
  },
  data() {
    const tableColumns = [
      {
        title: '设备名称',
        width: '10%',
        align: 'center',
        dataIndex: 'equipmentName',
      },
      {
        title: '设备id',
        width: '10%',
        align: 'center',
        dataIndex: 'equipmentId',
      },
      {
        title: '项目名称',
        width: '15%',
        align: 'center',
        dataIndex: 'projectName',
      },
      {
        title: '项目编码',
        width: '6%',
        align: 'center',
        dataIndex: 'projectCode',
      },

      {
        title: '门禁启用时间',
        width: '10%',
        align: 'center',
        dataIndex: 'createTime',
      },
      {
        title: '方向',
        width: '6%',
        align: 'center',
        dataIndex: 'direction',
      },
      {
        title: '门禁供应商',
        width: '10%',
        align: 'center',
        dataIndex: 'provider',
      },
      {
        title: '操作',
        width: '10%',
        align: 'center',
        dataIndex: 'detail',
        customRender: (text, row, index) => {
          return (
            <div>
              <span>
                <a
                  class="row-action-btn"
                  onClick={() => {
                    this.checkRow(row)
                  }}
                >
                  详情
                </a>
              </span>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },

      projectList: [],
      leaderList: [],
    }
  },
  computed: {
    typesMap() {
      return {
        direction: [
          { value: 0, label: '进' },
          { value: 1, label: '出' },
        ],
        status: [
          { value: 1, label: '启用' },
          { value: 0, label: '禁用' },
        ],
      }
    },
  },
  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const params = this.getPageParams(_pageNo, _pageSize)
        const res = await this.$axios.post('/attendance/equipmentDevice/query', {
          ...params,
        })
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async checkRow(row) {
      const res = await this.$axios.get(`/attendance/equipmentDevice/get/${row.id}`)
      this.$refs.DeviceDetail.start(res.data)
    },
    async exportExcel() {
      const params = this.getPageParams(this.pageNo, this.pageSize)
      const res = await this.$axios.post(
        '/attendance/equipmentDevice/export',
        {
          ...params,
        },
        {
          interceptors: {
            response: false,
          },
          responseType: 'arraybuffer',
        }
      )
      fileDownload(res.data, '设备入库台账.xlsx')
    },
  },
  async mounted() {
    this.projectList = await getProjectList()
  },
}
</script>

<style lang="less" scoped></style>
